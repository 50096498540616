.DraftEditor-root {
    font-family: 'Roboto', sans-serif;
}

.alignLeft > div {
    text-align: left;
}

.alignCenter > div {
    text-align: center;
}

.alignRight > div {
    text-align: right;
}

.alignJustify > div {
    text-align: justify;
}

.lineHeight1 > div {
    line-height: 1rem;
}
.lineHeight115 > div {
    line-height: 1.15rem;
}
.lineHeight150 > div {
    line-height: 1.5rem;
}
.lineHeight2 > div {
    line-height: 2rem;
}

.removeIndent > div {
    text-indent: 0;
}

.addIndent > div {
    text-indent: 4rem;
}